import { useEffect, useContext, useState, useRef } from "react";
import { Divider, Grid, Typography, Button } from "@mui/material";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { currencyFormatter, getReadableDateString } from "../../utils/helpers";
import {
  cardLight,
  cardVeryLight,
  doubtedBlue,
  normal,
  secondaryText,
  small,
  text,
  yellow,
} from "../../utils/themeContstants";
import CustomModal from "../custom/CustomModal";
import RivoxCoin from "../custom/RivoxCoin";
import { useNavigate } from "react-router-dom";
import useMiddleware from "../../api/useMiddleware";
import PayPalCheckoutButton from "./PayPalCheckoutButton";
import CashAppCheckout from "./CashAppCheckout";
import ShopifyCheckoutButton from "./ShopifyCheckoutButton";
import CryptoCheckout from "./CryptoCheckout";

import {
  createPayPalOrder,
  kinguinCheckout,
  paydoCheckout,
  poofCheckout,
  sellixCheckout,
  shopifyCheckout,
} from "../../api/user";
import { CDN_URL } from "../../utils/constants";
import IframeModal from "./iFrameModal";

const PayPalDepositModal = ({
  open,
  onClose,
  amount,
  closeWallet,
  description = null,
}) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [cashAppLoading, setCashAppLoading] = useState(false);
  const [shopifyLoading, setShopifyLoading] = useState(false);
  const [kinguinPaypalLoading, setKinguinPaypalLoading] = useState(false);
  const [kinguinProductUrl, setKinguinProductUrl] = useState(null); // State for product URL
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [termsHovered, setTermsHovered] = useState(false);
  const [iframeOpen, setIframeOpen] = useState(false);
  const cardLogo = `${CDN_URL}shopify-checkout-button.png`;
  const paypalLogo = `${CDN_URL}paypal-button.svg`;

  // Reference for the SDK iframe wrapper
  const wrapperRef = useRef(null);

  const handleClose = (e) => {
    setLoading(false);
    setCashAppLoading(false);
    setConfirmOpen(false);
    setTermsHovered(false);
    closeWallet(e);
    onClose();
  };

  // Fetch the product URL when the modal opens
  useEffect(() => {
    if (open) {
      const fetchProductUrl = async () => {
        try {
          const response = await kinguinCheckout(middleware, parseFloat(amount));
          if (response?.error) {
            updateStore({ type: SET_ERRORS, payload: response?.message });
          } else {
            setKinguinProductUrl(response?.checkout_url);
          }
        } catch (error) {
          console.error("Error fetching product URL:", error);
          updateStore({ type: SET_ERRORS, payload: "Failed to load the checkout. Please try again." });
        }
      };

      fetchProductUrl();
    } else {
      // Reset the product URL when the modal is closed
      setKinguinProductUrl(null);
    }
  }, [open, amount, middleware, updateStore]);

  // Initialize the Kinguin SDK after the product URL is fetched and button is rendered
  useEffect(() => {
    if (kinguinProductUrl) {

      const initializeSDK = () => {
        const productElement = document.querySelector('.productElement');
        if (wrapperRef.current && productElement && window.kinguinCheckoutSDK) {
          console.log('Initializing Kinguin SDK...');
          window.kinguinCheckoutSDK.init({
            wrapperSelector: '#kinguinIframeWrapper',
            productsSelector: '.productElement',
            productUrlAttribute: 'data-product',
            iframeId: 'kinguinCheckoutIframe',
            iframeWidth: '100%',
            iframeHeight: '900px',
            language: 'en',
            currency: 'USD',
            discount: 'MAJOR24',
            email: store?.user?.email,
          });
        } else {
          console.error('Kinguin Checkout SDK is not loaded or elements are not available.');
        }
      };

      // Delay initialization to ensure elements are rendered
      const timer = setTimeout(initializeSDK, 0);

      return () => clearTimeout(timer);
    }
  }, [kinguinProductUrl, store?.user?.email]);

  // Existing functions
  const handlePayPal = () => {
    setLoading(true);
    createPayPalOrder(middleware, parseFloat(amount))
      .then((res) => {
        setLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          window.open(res?.link?.href, "_self");
        }
      })
      .catch(() => {
        setLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: "Failed to initiate PayPal checkout.",
        });
      });
  };

  const handlePoof = () => {
    setCashAppLoading(true);
    poofCheckout(middleware, parseFloat(amount))
      .then((res) => {
        setCashAppLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          window.open(res?.url, "_self");
        }
      })
      .catch(() => {
        setCashAppLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: "Failed to initiate Poof checkout.",
        });
      });
  };

  const handleSellix = () => {
    setCashAppLoading(true);
    sellixCheckout(middleware, parseFloat(amount))
      .then((res) => {
        console.log("amount", amount);
        setCashAppLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          window.open(res?.checkout_url, "_self");
        }
      })
      .catch(() => {
        setCashAppLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: "Failed to initiate Sellix checkout.",
        });
      });
  };

  const handleKinguin = () => {
    setShopifyLoading(true);
    kinguinCheckout(middleware, parseFloat(amount))
      .then((res) => {
        setShopifyLoading(false);
        if (res?.error) {
          console.log("error", res?.error)
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          window.open(res?.checkout_url, "_self");
        }
      })
      .catch((e) => {
        console.log("error", e)
        setShopifyLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: "Failed to initiate Kinguin checkout.",
        });
      });
  };

  const handlePaydo = () => {
    setShopifyLoading(true);
    paydoCheckout(middleware, parseFloat(amount))
      .then((res) => {
        setShopifyLoading(false);
        if (res?.error) {
          console.log("error", res?.error)
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          window.open(res?.checkout_url, "_self");
        }
      })
      .catch((e) => {
        console.log("error", e)
        setShopifyLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: "Failed to initiate Paydo checkout.",
        });
      });
  };

  const handleKinguinIframe = () => {

    if (kinguinProductUrl) {
      setIframeOpen(true);
    }
  }

  const handleKinguinPaypal = () => {
    setKinguinPaypalLoading(true);
    kinguinCheckout(middleware, parseFloat(amount))
      .then((res) => {
        setKinguinPaypalLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          window.open(res?.checkout_url, "_self");
        }
      })
      .catch(() => {
        setKinguinPaypalLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: "Failed to initiate Kinguin PayPal checkout.",
        });
      });
  };

  const handleShopify = () => {
    setShopifyLoading(true);
    shopifyCheckout(middleware, parseFloat(amount))
      .then((res) => {
        console.log("amount", amount);
        setShopifyLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          window.open(res?.checkout_url, "_self");
        }
      })
      .catch(() => {
        setShopifyLoading(false);
        updateStore({
          type: SET_ERRORS,
          payload: "Failed to initiate Shopify checkout.",
        });
      });
  };

  const styles = {
    width: {
      width: "100%",
    },
    container: {
      width: "100%",
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
    value: {
      fontSize: 16,
      fontWeight: 800,
      color: text,
    },
    meta: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 400,
      textAlign: "center",
      maxWidth: 500,
    },
    warning: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
      maxWidth: 400,
    },
    paymentContainer: {
      width: "100%",
      borderRadius: 1,
      border: `1px solid ${cardVeryLight}`,
      padding: 2,
      backgroundColor: cardLight,
    },
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
      maxWidth: 500,
    },
  };

  return (
    <>
      {/* Existing Modals and Components */}
      {/* <ConfirmCashAppModal ... /> */}

      <CustomModal
        open={open}
        onClose={onClose}
        title="Complete Purchase"
        titleSize="large"
        bottomContent={
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              gap={{ xs: 1 }}
            >

              {/* Existing Buttons */}
              {/* <Grid item sx={styles.width}>
                <PayPalCheckoutButton
                  loading={kinguinPaypalLoading}
                  onClick={handleKinguinPaypal}
                />
              </Grid> */}

              <Grid item sx={styles.width}>
                <CryptoCheckout
                  loading={cashAppLoading}
                  onClick={handleSellix}
                />
              </Grid>

              <Grid item sx={styles.width}>
                <ShopifyCheckoutButton
                  loading={shopifyLoading}
                  onClick={handlePaydo}
                />
              </Grid>

              {/* New "Deposit With Kinguin" Button */}
              {kinguinProductUrl ? (
                <Grid item sx={styles.width}>
                  <Button
                    variant="contained"
                    fullWidth
                    className="productElement"
                    data-product={kinguinProductUrl}
                    onClick={handleKinguinIframe}
                  >
                    Pay Now With
                    <Grid item sx={styles.icon}>
                      <img
                        src={paypalLogo}
                        draggable={false}
                        alt="paypal-logo"
                        width={80}
                        style={{ marginLeft: "10px" }}
                      />
                    </Grid>
                  </Button>

                </Grid>
              ) : (
                // Optionally show a loading indicator or disable the button
                <Grid item sx={styles.width}>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled
                  >
                    Loading Checkout
                  </Button>
                </Grid>
              )}
              {/* {kinguinProductUrl ? (
                <Grid item sx={styles.width}>
                  <Button
                    variant="contained"
                    fullWidth
                    className="productElement"
                    data-product={kinguinProductUrl}
                    onClick={handleKinguinIframe}
                  >
                    Pay Now With
                    <Grid item sx={styles.icon}>
                      <img
                        src={cardLogo}
                        draggable={false}
                        alt="shopify-card-logo"
                        width={30}
                        style={{ marginLeft: "10px" }}
                      />


                    </Grid>
                  </Button>

                </Grid>
              ) : (
                // Optionally show a loading indicator or disable the button
                <Grid item sx={styles.width}>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled
                  >
                    Loading Kinguin...
                  </Button>
                </Grid>
              )} */}

              {/* Terms and Conditions */}
              <Grid item sx={{ width: "100%", marginTop: 1 }}>
                <Typography sx={styles.meta}>
                  By purchasing these coins you agree to our{" "}
                  <span
                    style={{
                      fontWeight: 700,
                      color: termsHovered ? doubtedBlue : text,
                      cursor: termsHovered ? "pointer" : "default",
                    }}
                    onMouseEnter={() => setTermsHovered(true)}
                    onMouseLeave={() => setTermsHovered(false)}
                    onClick={(e) => {
                      navigate("/support/terms");
                      handleClose(e);
                    }}
                  >
                    Terms of Service
                  </span>{" "}
                  and you are aware that when you purchase these coins, you
                  cannot cancel or refund the order once they are received.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        }
      >
        {/* Promotional Message */}
        <Grid
          item
          sx={{
            width: "100%",
            marginBottom: 1,
            border: "1px solid white",
            borderRadius: "8px",
            padding: "5px",
          }}
        >
          <Typography sx={styles.warning}>
            Enjoy 8% Off PayPal / Card Deposits! Get an 8% discount on your
            deposits when using the code{" "}
            <span
              style={{
                fontWeight: 700,
                color: yellow,
                cursor: "default",
              }}
            >
              MAJOR24
            </span>{" "}
            at checkout. Don't miss out on this limited-time offer!
          </Typography>
        </Grid>

        {/* Purchase Summary */}
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={{ xs: 2 }}
        >
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              {description && (
                <>
                  <Grid item>
                    <Typography sx={styles.description}>
                      {description}
                    </Typography>
                  </Grid>

                  <Grid item sx={styles.width}>
                    <Divider
                      sx={{
                        width: "100%",
                        backgroundColor: text,
                        opacity: 0.15,
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item>
                <Typography
                  sx={{ fontSize: normal, fontWeight: 700, color: text }}
                >
                  Purchase Summary
                </Typography>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RivoxCoin size={40} />
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: normal,
                            color: text,
                            fontWeight: 700,
                          }}
                        >
                          {amount} Coins
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: normal,
                            color: secondaryText,
                            fontWeight: 500,
                          }}
                        >
                          One-time charge on {getReadableDateString(new Date())}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Divider
              sx={{ width: "100%", backgroundColor: text, opacity: 0.15 }}
            />
          </Grid>

          <Grid item sx={styles.container}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography sx={styles.label}>Tax</Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.label}>
                      {currencyFormatter().format(0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography sx={{ ...styles.value, fontWeight: 600 }}>
                      Total
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.value}>
                      {currencyFormatter().format(amount)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


          <IframeModal
            open={iframeOpen}
            onClose={() => setIframeOpen(false)}
            title="Checkout"
            iframeUrl={kinguinProductUrl}
            height="800px"
          />
          {/* Wrapper for the SDK iframe */}
          {/* <div id="kinguinIframeWrapper" ref={wrapperRef}></div> */}
        </Grid>
      </CustomModal>
    </>
  );
};

export default PayPalDepositModal;
