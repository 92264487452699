import React, { Suspense } from 'react';
import { getColor } from '../../utils/getColor';

const backBlingComponents = {
  "backpack_backBling": React.lazy(() => import("./types/BackpackOne")),
  "cat_backBling": React.lazy(() => import("./types/CatOne")),
  "cat_2_backBling": React.lazy(() => import("./types/CatTwo")),
  "dog_backBling": React.lazy(() => import("./types/DogOne")),
  "sword_katana_backBling": React.lazy(() => import("./types/Katana")),
  "sword_backBling": React.lazy(() => import("./types/SwordOne")),
  "guitar_backBling": React.lazy(() => import("./types/Guitar")),
  "axe_backBling": React.lazy(() => import("./types/Axe")),
  "satchel_backBling": React.lazy(() => import("./types/Satchel")),
  "foggy": React.lazy(() => import("./types/Foggy")),
  "vandal_backBling": React.lazy(() => import("./types/Vandal")),
  "phantom_backBling": React.lazy(() => import("./types/Phatom")),
  "angel_devil": React.lazy(() => import("./types/AngelDevil")),
  "coins": React.lazy(() => import("./types/Raining")),
  "scar": React.lazy(() => import("./types/Scar")),
  "spaz": React.lazy(() => import("./types/Spaz")),
  "wire_bat": React.lazy(() => import("./types/WireBat")),
  "jerry": React.lazy(() => import("./types/Jerry")),
  "loser": React.lazy(() => import("./types/Loser")),
  "money_calling": React.lazy(() => import("./types/MoneyCalling")),
  "kaw": React.lazy(() => import("./types/Kaw")),
  "shoulder_snake": React.lazy(() => import("./types/Snake")),
  "wings": React.lazy(() => import("./types/Wings")),
  "lv_bag": React.lazy(() => import("./types/LouisBag")),
  "middle_finger": React.lazy(() => import("./types/MiddleFinger")),
  "fighting_stance": React.lazy(() => import("./types/FightingStance")),
  "pochita": React.lazy(() => import("./types/Pochita")),
  "esp": React.lazy(() => import("./types/ESP")),
};

const BackBling = ({ type, skinColor }) => {
  const BackBlingComponent = backBlingComponents[type];

  if (!BackBlingComponent) return null;

  const props = {};

  // Components that require skinColor prop
  const skinColorComponents = [
    "wire_bat",
    "loser",
    "money_calling",
    "middle_finger",
    "fighting_stance",
  ];

  if (skinColorComponents.includes(type)) {
    props.skinColor = getColor(skinColor);
  }

  return (
    <Suspense fallback={null}>
      <BackBlingComponent {...props} />
    </Suspense>
  );
};

export default BackBling;
