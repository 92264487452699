import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { HiOutlineUserGroup } from "react-icons/hi";
import { getReferrals, createReferralCode } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import usePagination from "../../utils/usePagination";
import ReferralItem from "./ReferralItem"; // Component to render individual referral items
import { text } from "../../utils/themeContstants";
import { DISPUTES_LIMIT } from "../../utils/limits";
import PrimaryButton from "../custom/PrimaryButton";

const ReferralsDashboard = () => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const store = useContext(StoreContext);
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [referrals, setReferrals] = useState(null);
  const [rank, setRank] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [newCode, setNewCode] = useState(""); // State for new referral code

  const handleGetReferrals = () => {
    getReferrals(middleware, skip).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setReferrals(res.referralStats);
      }
    });
  };

  const handleCreateReferralCode = () => {
    createReferralCode(middleware, newCode).then((res) => {
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setNewCode(""); // Clear the input field
        handleGetReferrals(); // Refresh the referral list
      }
    });
  };

  useEffect(() => {
    console.log("referrals", referrals);
  }, [referrals]);

  useEffect(() => {
    if (referrals == null) {
      handleGetReferrals();
    }
  }, []);

  const styles = {
    width: {
      width: "100%",
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <HiOutlineUserGroup style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>Referrals</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Box sx={{ textAlign: "left", marginBottom: "2rem" }}>
            {/* Input and Button for creating new referral code */}
            <Typography variant="h6" component="div" color="white">
              Create New Referral Code
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "1rem", alignItems: "center", gap: "5px" }}>
              <TextField
                label="Referral Code"
                variant="outlined"
                value={newCode}
                onChange={(e) => setNewCode(e.target.value)}
              // sx={{ marginRight: "1rem" }}
              />
              <PrimaryButton
              
                type="purple"
                label="Create"
                onClick={handleCreateReferralCode}
              />

            </Box>

            <Typography variant="h3" component="div" color="green">
              {rank?.rank}
            </Typography>
            <Grid container spacing={2} style={{ gap:"20px" }}>
              {referrals?.map((referral, i) => (
                <Grid item xs={12} sm={6} md={4} key={i} sx={{ marginBottom: "2rem" }}>
                  <ReferralItem referral={referral} />
                </Grid>
              ))}
            </Grid>


          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ReferralsDashboard;
