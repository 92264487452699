import React, { Suspense } from 'react';
import { getColor } from '../../utils/getColor';

const facialHairComponents = {
  "beard": React.lazy(() => import("./types/Beard")),
  "goatee": React.lazy(() => import("./types/Goatee")),
  "handle_bar": React.lazy(() => import("./types/Handlebar")),
  "musketeer": React.lazy(() => import("./types/Musketeer")),
  "strong_man": React.lazy(() => import("./types/StrongMan")),
  "leftovers": React.lazy(() => import("./types/Leftovers")),
};

const FacialHair = ({ type, color }) => {
  const FacialHairComponent = facialHairComponents[type];

  if (!FacialHairComponent) return null;

  const props = {};

  if (type !== "leftovers") {
    props.color = getColor(color);
  }

  return (
    <Suspense fallback={null}>
      <FacialHairComponent {...props} />
    </Suspense>
  );
};

export default FacialHair;
