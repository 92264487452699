import React, { Suspense } from 'react';

const noseComponents = {
  "squidward": React.lazy(() => import("./types/Squidward")),
  "pointy": React.lazy(() => import("./types/Pointy")),
  "default_nose": React.lazy(() => import("./types/Default")),
  "clown": React.lazy(() => import("./types/Clown")),
  "injured": React.lazy(() => import("./types/Injured")),
  "squidward_pierced": React.lazy(() => import("./types/SquidwardPierced")),
  "pointy_pierced": React.lazy(() => import("./types/PointyPierced")),
  "default_nose_pierced": React.lazy(() => import("./types/DefaultPierced")),
  "injured_pierced": React.lazy(() => import("./types/InjuredPierced")),
  "nose_hoop": React.lazy(() => import("./types/NoseHoop")),
};

const Nose = ({ type }) => {
  const NoseComponent = noseComponents[type];

  if (!NoseComponent) return null;

  return (
    <Suspense fallback={null}>
      <NoseComponent />
    </Suspense>
  );
};

export default Nose;
