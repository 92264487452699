import React, { Suspense } from 'react';

const earringComponents = {
  "default_earring": React.lazy(() => import("./types/Earring")),
  "retro_earring": React.lazy(() => import("./types/Retro")),
  "airpod": React.lazy(() => import("./types/Airpod")),
  "ear_smoke": React.lazy(() => import("./types/EarSmoke")),
};

const Earrings = ({ type }) => {
  const EarringComponent = earringComponents[type];

  if (!EarringComponent) return null;

  return (
    <Suspense fallback={null}>
      <EarringComponent />
    </Suspense>
  );
};

export default Earrings;
