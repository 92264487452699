import React, { Suspense } from 'react';

const glassesComponents = {
  "aviators": React.lazy(() => import("./types/Aviators")),
  "clout_goggles": React.lazy(() => import("./types/CloutGoggles")),
  "3d": React.lazy(() => import("./types/ThreeD")),
  "deal_with_it": React.lazy(() => import("./types/DealWithIt")),
  "anti_mainstream": React.lazy(() => import("./types/AntiMainstream")),
  "ozzy": React.lazy(() => import("./types/Ozzy")),
  "gojo": React.lazy(() => import("./types/Gojo")),
  "cartier_shades": React.lazy(() => import("./types/CartierShades")),
};

const Glasses = ({ type }) => {
  const GlassesComponent = glassesComponents[type];

  if (!GlassesComponent) return null;

  return (
    <Suspense fallback={null}>
      <GlassesComponent />
    </Suspense>
  );
};

export default Glasses;
